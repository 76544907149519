import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { Validators,FormBuilder,FormGroup, FormControl } from '@angular/forms';
import { EmailValidator } from '@angular/forms';
import { MatSelectModule } from '@angular/material';
import { ApiService } from '../../app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { HttpResponse } from "@angular/common/http";

@Component({
  selector: 'app-uplaod-xsip-order',
  templateUrl: './uplaod-xsip-order.component.html',
  styleUrls: ['./uplaod-xsip-order.component.scss']
})
export class UplaodXsipOrderComponent implements OnInit {
	csvFileSrc: any;
	file: any;
	fileName: String;
	@ViewChild('myInput') myInputVariable: any;
  constructor(public _dialog: MatDialog, public dialogRef: MatDialogRef<UplaodXsipOrderComponent>, private _snackbar: MatSnackBar, private _apiService:ApiService) { }

  ngOnInit() {
  }


   readURL(files: Event): void {
    if (files && files[0]) {
      this.file = files[0];
      this.fileName =  this.file.name;
      const reader = new FileReader();
      reader.onload = e => {
        this.csvFileSrc = reader.result;
        console.log("Reader result", reader.result);
      }

      reader.readAsDataURL(this.file);
      console.log("File value in file upload type", files[0].type);
      console.log("File reader", reader);
    }
  }

  uploadCSV() {
    let token = sessionStorage.getItem('access_token');
    let data = {
      file: this.file,
    };
    this._apiService.uploadXsipOrder(data, token).subscribe(res => {
      if (res instanceof HttpResponse) {
        let responseObj = JSON.parse(res.body);
        if (responseObj.status == "OK") {
          this._snackbar.open("Upload Successfull", "X");
          this.onClose();
        }
      }
    }, err => {
    	this.reset();
    	if(err.message == "upload Exel File"){
    		this._snackbar.open(err.message, 'X');
    	}else{
    		this._snackbar.open("Error In File Format", 'X');
    	}
        
    });
  }

  reset() {
    this.myInputVariable.nativeElement.value = "";
  }

  onClose(){
  	this.dialogRef.close();
  }


}
