export const menus = [
    {
        'name': 'Dashboard',
        'sidebarname': 'Dashboard',
        'icon': 'dashboard',
        'link': '/auth/dashboard',
        'open': false,
        'image': '../../../assets/images/Dashbaord.png',
        'status': false
        // 'chip': { 'value': 1, 'color': 'accent' },
        // 'sub': [
        //     {
        //         'name': 'Dashboard',
        //         'link': '/auth/dashboard',
        //         'icon': 'dashboard',
        //         'chip': false,
        //         'open': true,
        //     }
        // ]
    },
     {
        //commented for loadissue 'name': 'Free Users',
        // 'sidebarname': 'FreeUser',
        // 'icon': 'widgets',
        // 'link': 'tables/freeUser',
        // 'open': false,
        // 'image': '../../../assets/images/User_Details.png',
        //end commented for loadissue 'status': false,
        // 'sub': [
        //     {
        //         'name': 'Buttons',
        //         'link': 'material-widgets/buttons',
        //         'icon': 'indeterminate_check_box',
        //         'chip': false,
        //         'open': false,
        //         'status': false,
        //     },
        //     {
        //         'name': 'List',
        //         'link': 'material-widgets/list',
        //         'icon': 'list',
        //         'chip': false,
        //         'open': false,
        //         'status': false,
        //     }
        // ]
    },
    {
        'name': 'Paid Users',
        'sidebarname': 'PaidUser',
        'icon': 'widgets',
        'link':  false,
        'open': false,
        'image': '../../../assets/images/User_Details.png',
        'status': false,
        'sub': [
            {
                'name': 'User Details',
                'sidebarname': 'User_Details',
                'link': 'tables/featured',
                'icon': 'indeterminate_check_box',
                'image': '../../../assets/images/round_icon.png',
                // 'chip': false,
                'open': false,
                'status': false,
            },
            //commented for loadissue {
            //     'name': 'Subscription',
            //     'sidebarname': 'Subscription',
            //     'link': 'tables/payments',
            //     'image': '../../../assets/images/round_icon.png',
            //     'icon': 'list',
            //     // 'chip': false,
            //     'open': false,
            //     'status': false,
            //end commented for loadissue },
            {
                'name': 'Manual KYC Verify',
                'sidebarname': 'manual_kyc_verified',
                'link': 'tables/manual-kyc-users',
                'image': '../../../assets/images/round_icon.png',
                'icon': 'list',
                // 'chip': false,
                'open': false,
                'status': false,
            }
        ]
        //     {

        //         'name': 'Stepper',
        //         'link': 'material-widgets/stepper',
        //         'icon': 'view_week',
        //         'chip': false,
        //         'open': false,

        //     },
        //     {
        //         'name': 'Expansion',
        //         'link': 'material-widgets/expansion',
        //         'icon': 'web_aaset',
        //         'chip': false,
        //         'open': false,
        //     },
        //     {
        //         'name': 'Progress Spinner',
        //         'link': 'material-widgets/spinner',
        //         'icon': 'cached',
        //         'chip': false,
        //         'open': false,
        //     },
        //     {
        //         'name': 'Cards',
        //         'link': 'material-widgets/cards',
        //         'icon': 'crop_16_9',
        //         'chip': false,
        //         'open': false,
        //     },
        //     {
        //         'name': 'Icons',
        //         'link': 'material-widgets/icons',
        //         'icon': 'gif',
        //         'chip': false,
        //         'open': false,
        //     },
        //     {

        //         'name': 'AutoComplete',
        //         'link': 'material-widgets/autocomplete',
        //         'icon': 'get_app',
        //         'chip': false,
        //         'open': false,
        //     },
        //     {
        //         'name': 'CheckBox',
        //         'link': 'material-widgets/checkbox',
        //         'icon': 'check_box',
        //         'chip': false,
        //         'open': false,
        //     },
        //     {
        //         'name': 'DatePicker',
        //         'link': 'material-widgets/datepicker',
        //         'icon': 'date_range',
        //         'chip': false,
        //         'open': false,
        //     },

        //     {
        //         'name': 'Slider',
        //         'link': 'material-widgets/slider',
        //         'icon': 'keyboard_tab',
        //         'chip': false,
        //         'open': false,
        //     },
        //     {
        //         'name': 'Slide Toggle',
        //         'link': 'material-widgets/slide-toggle',
        //         'icon': 'album',
        //         'chip': false,
        //         'open': false,
        //     },
        //     {
        //         'name': 'Menu',
        //         'icon': 'menu',
        //         'link': 'material-widgets/menu',
        //         'chip': false,
        //         'open': false,
        //     },
        //     {
        //         'name': 'Progress Bar',
        //         'link': 'material-widgets/progress-bar',
        //         'icon': 'trending_flat',
        //         'chip': false,
        //         'open': false,
        //     },
        //     {
        //         'name': 'Input',
        //         'icon': 'input',
        //         'link': 'material-widgets/input',
        //         'open': false,
        //     },
        //     {
        //         'name': 'Radio',
        //         'icon': 'radio_button_checked',
        //         'link': 'material-widgets/radio',
        //         'chip': false,
        //         'open': false,
        //     },
        //     {
        //         'name': 'Select',
        //         'icon': 'select_all',
        //         'link': 'material-widgets/select',
        //         'open': false,
        //     },
        // ]
    },
    // {
    //     'name'   : 'Forms',
    //     'icon'   : 'mode_edit',
    //     'open'   : false,
    //     'link'   : false,
    //     'sub'    :  [
    //                     {
    //                         'name': 'Template Driven',
    //                         'icon': 'mode_edit',
    //                         'open'   : false,
    //                         'link':'forms/template_forms'
    //                     },
    //                     {
    //                         'name': 'Reactive Forms',
    //                         'icon': 'text_fields',
    //                         'open'   : false,
    //                         'link':'forms/reactive_forms'
    //                     }
    //                 ]
    // },
    {
        'name': 'Advisors',
        'sidebarname': 'Advisor',
        'icon': 'list',
        'link': false,
        'open': false,
        'image': '../../../assets/images/Advisor.png',
        'status': false,
        // 'chip': { 'value': 2, 'color': 'accent' },
            'sub': [
                {
                    'name': 'Advisor Details',
                    'sidebarname': 'Advisor_Details',
                    'icon': 'filter_list',
                    'link': 'tables/advisor',
                    'image': '../../../assets/images/round_icon.png',
                    'status': false,
                    'open': false,
                },
                {
                    'name': 'Consultation',
                    'sidebarname': 'Advisor_Consultation',
                    'icon': 'done_all',
                    'link': 'tables/consultation',
                    'image': '../../../assets/images/round_icon.png',
                    'status': false,
                    'open': false,
                },
                {
                    'name': 'Call Logs',
                    'sidebarname': 'Advisor_Consultation',
                    'icon': 'done_all',
                    'link': 'tables/callLogs',
                    'image': '../../../assets/images/round_icon.png',
                    'status': false,
                    'open': false,
                },
                {
                    'name': 'Tickets',
                    'sidebarname': 'Tickets',
                    'open': false,
                    'link': 'tables/listAllTickets',
                    'icon': 'grade',
                    'image': '../../../assets/images/round_icon.png',
                    'status': false
                },
            ]

    },
    {
        'name': 'Partners',
        'sidebarname': 'Partner',
        'icon': 'list',
        'link': false,
        'open': false,
        'image': '../../../assets/images/Partners.png',
        'status': false,
        // 'chip': { 'value': 2, 'color': 'accent' },
            'sub': [
                {
                    'name': 'Partner Details',
                    'sidebarname': 'Partner_Details',
                    'icon': 'filter_list',
                    'link': 'tables/partenrs',
                    // 'link': 'tables/ecard-purchase-history',
                    'image': '../../../assets/images/round_icon.png',
                    'open': false,
                    'status': false,
                },
                {
                    'name': 'Partner Payments',
                    'sidebarname': 'Partner_Payment',
                    'icon': 'done_all',
                    'link': 'tables/partnerPayments',
                    // 'link': 'tables/physical-card-purchase-history',
                    'image': '../../../assets/images/round_icon.png',
                    'open': false,
                    'status': false,
                },
                {
                    'name': 'Payment History',
                    'sidebarname': 'Payments_History',
                    'icon': 'filter_center_focus',
                    'link': 'tables/paymentHistory',
                    // 'link': 'tables/card-redemption-history',
                    'image': '../../../assets/images/round_icon.png',
                    'open': false,
                    'status': false,
                }
            ]

    },
    // {
    //     'name': 'Payment',
    //     'icon': 'view_module',
    //     'open': false,
    //     'link': false,
    //     'image': '../../../assets/images/Payment.png',
    //     'status': false,
    //     'sub': [
    //         {
    //             'name': 'User Payment',
    //             'icon': 'chat',
    //             'link': 'tables/payments',
    //             'open': false,
    //             'image': '../../../assets/images/User_Details.png',
    //             'status': false,
    //         },
    //         {
    //             'name': 'Partner Payment',
    //             'icon': 'mail',
    //             'link': 'tables/partnerPayments',
    //             'open': false,
    //             'image': '../../../assets/images/Partners.png',
    //             'status': false,
    //         },
    //         // {
    //         //     'name': 'Editor',
    //         //     'icon': 'editor',
    //         //     'link': 'editor/editor',
    //         //     'open': false,
    //         // }
    //     ]
    // },
    {
        'name': 'Administration',
        'sidebarname': 'Administration',
        'icon': 'list',
        'link': false,
        'open': false,
        'image': '../../../assets/images/Partners.png',
        'status': false,
        // 'chip': { 'value': 2, 'color': 'accent' },
            'sub': [
                {
                    'name': 'Staff Details',
                    'sidebarname': 'Staff_details',
                    'icon': 'filter_list',
                    'link': 'tables/othersDeatail',
                    'image': '../../../assets/images/round_icon.png',
                    'open': false,
                    'status': false
                },
                {    
                    'name': 'Roles',
                    'sidebarname': 'Roles',
                    'open': false,
                    'link': 'tables/role',
                    'icon': 'label',
                    'image': '../../../assets/images/round_icon.png',
                    'status': false
                },
                {    
                    'name': 'Offers',
                    'sidebarname': 'Offers',
                    'open': false,
                    'link': 'tables/offers',
                    'icon': 'label',
                    'image': '../../../assets/images/round_icon.png',
                    'status': false
                },
                // {    
                //     'name': 'Referral Code',
                //     'sidebarname': 'Referral_Code',
                //     'open': false,
                //     'link': 'tables/referralCode',
                //     'icon': 'label',
                //     'image': '../../../assets/images/round_icon.png',
                //     'status': false
                // },
                {    
                    'name': 'Cvl/Kra Users',
                    'sidebarname': 'CVL_KRA_Users',
                    'open': false,
                    'link': 'tables/cvlKra',
                    'icon': 'label',
                    'image': '../../../assets/images/round_icon.png',
                    'status': false
                },
                {    
                    'name': 'Transactions',
                    'sidebarname': 'MF_Transactions',
                    'open': false,
                    'link': 'tables/allTransactions',
                    'icon': 'label',
                    'image': '../../../assets/images/round_icon.png',
                    'status': false
                },
                
                {    
                    'name': 'Assumptions',
                    'sidebarname': 'Assumption',
                    'open': false,
                    'link': 'tables/assumption',
                    'icon': 'label',
                    'image': '../../../assets/images/round_icon.png',
                    'status': false
                },
                {
                    'name': 'Fund Explorer',
                    'sidebarname': 'Fund_Explorer',
                    'open': false,
                    'link': 'tables/funds',
                    'icon': 'grade',
                    'image': '../../../assets/images/round_icon.png',
                    'status': false
                },
            ]

    },

    {
        'name': 'Account Settings',
        'open': false,
        'link': 'tables/accounts',
        'icon': 'grade',
        'image': '../../../assets/images/Account_Settings.png',
        'status': true
    },

    // , {
    //     'name': 'Logout',
    //     'icon': 'content_copy',
    //     'open': false,
    //     'link': false,
    //     // 'sub': [
    //     //     {
    //     //         'name': 'Login',
    //     //         'icon': 'work',
    //     //         'open': false,
    //     //         'link': '../login',
    //     //     }, {
    //     //         'name': 'Services',
    //     //         'icon': 'local_laundry_service',
    //     //         'open': false,
    //     //         'link': 'pages/services',
    //     //     }, {
    //     //         'name': 'Contact',
    //     //         'icon': 'directions',
    //     //         'open': false,
    //     //         'link': 'pages/contact'
    //     //     }
    //     // ]
    // }
    // , {

    //     'name': 'Charts',
    //     'icon': 'pie_chart_outlined',
    //     'open': false,
    //     'link': false,
    //     'sub': [
    //         {
    //             'name': 'chartjs',
    //             'icon': 'view_list',
    //             'link': 'charts/chartjs',
    //             'open': false,

    //         },
    //         {
    //             'name': 'ngx-chart',
    //             'icon': 'show_chart',
    //             'open': false,
    //             'link': 'charts/ngx-charts',
    //         },
    //         {
    //             'name': 'nvd3',
    //             'icon': 'pie_chart',
    //             'open': false,
    //             'link': 'charts/nvd3-charts',
    //         }
    //     ]
    // }, {
    //     'name': 'maps',
    //     'icon': 'map',
    //     'open': false,
    //     'link': false,
    //     'sub': [
    //         {
    //             'name': 'google-map',
    //             'icon': 'directions',
    //             'link': 'maps/googlemap',
    //             'open': false,
    //         },
    //         {
    //             'name': 'leaflet-map',
    //             'icon': 'directions',
    //             'link': 'maps/leafletmap',
    //             'open': false,
    //         }
    //     ]
    // }
    //commented for loadissue {
    //     'name': 'FinXpert History',
    //     'sidebarname': 'FinXpertHistory',
    //     'icon': 'widgets',
    //     'link':  false,
    //     'open': false,
    //     'image': '../../../assets/images/User_Details.png',
    //     'status': false,
    //     'sub': [
    //         {
    //             'name': 'Corporate Bulk Orders',
    //             'sidebarname': 'cardredemption_purchase_history',
    //             'link': 'tables/corporate-bulk-orders',
    //             'icon': 'indeterminate_check_box',
    //             'image': '../../../assets/images/round_icon.png',
    //             // 'chip': false,
    //             'open': false,
    //             'status': false,
    //         },
    //         {
    //             'name': 'eCard Purchase History',
    //             'sidebarname': 'ecard_purchase_history',
    //             'link': 'tables/ecard-purchase-history',
    //             'icon': 'indeterminate_check_box',
    //             'image': '../../../assets/images/round_icon.png',
    //             // 'chip': false,
    //             'open': false,
    //             'status': false,
    //         },
    //         {
    //             'name': 'Physical Card Purchase History',
    //             'sidebarname': 'physicalcard_purchase_history',
    //             'link': 'tables/physical-card-purchase-history',
    //             'image': '../../../assets/images/round_icon.png',
    //             'icon': 'list',
    //             // 'chip': false,
    //             'open': false,
    //             'status': false,
    //         },
    //         // {
    //         //     'name': 'Card Redemption History',
    //         //     'sidebarname': 'card_redemption_history',
    //         //     'link': 'tables/card-redemption-history',
    //         //     'image': '../../../assets/images/round_icon.png',
    //         //     'icon': 'list',
    //         //     // 'chip': false,
    //         //     'open': false,
    //         //     'status': true,
    //         // }
    //     ]
    //end commented for loadissue },
    {
        'name': 'All Transaction History',
        'sidebarname': 'all_transaction_history',
        'link':  'tables/all-transaction-history',
        'icon': 'widgets',
        'open': false,
        'image': '../../../assets/images/User_Details.png',
        'status': false,
    },
    {
        'name': 'Marketing Leads',
        'sidebarname': 'Administration',
        'link': 'tables/marketing-leads',
        'icon': 'list',
        'open': false,
        'image': '../../../assets/images/consultation.png',
        'status': false,
    }
];
