import { Component, OnInit, Input } from '@angular/core';
import { menus } from './menu-element';
import { ApiService } from '../../app.service';
import { HttpResponse } from "@angular/common/http";
import { MatSnackBar } from '@angular/material';
import { environment } from '../../../environments/environment';

const API_URL = environment.baseUrl;
@Component({
    selector: 'cdk-sidemenu',
    templateUrl: './sidemenu.component.html',
    styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {
    permissionObj: any;
    userPermissions: Array<any>;
    imageSrc: any;

    // menus: any;
    @Input() iconOnly: boolean = false;
    public menus = menus;
    token: any;
    userId: any;
    // userDetailsObj: any;

    constructor(private _apiService: ApiService,
        private _snackbar: MatSnackBar) {
        // this.menus = menus;
        // this.permissionObj = JSON.parse(sessionStorage.getItem('userDetailsObj'));
        // this.addPermissionForMenu();
    }

    ngOnInit() {
        this.token = sessionStorage.getItem('access_token');
        this.permissionObj = JSON.parse(sessionStorage.getItem('userDetailsObj'));
        // this.userDetailsObj = JSON.parse(sessionStorage.getItem('userDetailsObj'));
        this.menus = menus;
        
        this.userId = this.permissionObj.userProfile.userId;
        // this.getProfileImage();
        console.log(this.menus, "this.menus")
        this.userPermissions = this.permissionObj.userPermissions;
        // this.addPermissionForMenu(this.userPermissions, this.permissionObj);
        console.log(this.userPermissions, "this.userPermissions");
        this.getProfileImage();
        
        this.permissionForMenus(this.userPermissions, this.permissionObj.userProfile.role);
    }

    // for image preview and upload
    readURL(files: Event): void {
        if (files && files[0]) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = e => this.imageSrc = reader.result;
            reader.readAsDataURL(file);
            console.log("File value in file upload", file);
            this.saveProfilePicture(file);
        }
    }

    saveProfilePicture(file) {
        console.log("Session storage user Object", this.permissionObj);
        let data = {
            profileImage: file,
            email: this.userId
        };
        // this.loaderService.display(true);
        this._apiService.postProfilePicture(data, this.token).subscribe(res => {
            console.log(res, "res of profile pic post");
            if (res instanceof HttpResponse) {
                let responseObj = JSON.parse(res.body);
                console.log("Response is of type HttpResponse and value of res.body = ", responseObj);
                if (responseObj.status == "OK") {
                    this._snackbar.open("Upload Successfull", 'X', { duration: 5000 });
                }
            }
        }, err => {
            console.log("Error in pic upload", err);
            this._snackbar.open(err.error.message, 'X', { duration: 5000 });
        });
    }

    getProfileImage() {
        this.imageSrc = API_URL + '/backend/public/backend-profile-image/'+this.userId;
    }


    // addPermissionForMenu(data, profile) {
    //     menus.forEach(function (val, key) {
    //         // console.log('forrrrrrrrr module 1', val , key);
    //         data.forEach(function (v, k) {
    //             // console.log('forrrrrrrrr module 2', v , k);
    //             if(val.sidebarname == 'all_transaction_history' && (profile.userProfile.role.roleName == 'advisor' || profile.userProfile.role.roleName == 'Admin')){
    //                 val.status = true;
    //             }
    //             if (v.moduleName == val.sidebarname) {
    //                 val.status = v.status;
    //                 console.log(menus, "this.menus")
    //             }
    //         })
    //     })

    //     menus.forEach(function (value, keys) {
    //         // console.log('forrrrrrrrr permiss 1', value , keys);
    //         if (value.sub) {
    //             value.sub.forEach(function (val, key) {
    //                 // console.log('forrrrrrrrr permiss 2', val , key);
    //                 if((val.sidebarname == 'Payments_History' && profile.userProfile.role.roleName == 'Admin') || 
    //                    (val.sidebarname == 'Tickets' && (profile.userProfile.role.roleName == 'advisor' || profile.userProfile.role.roleName == 'Admin') || 
    //                    (val.sidebarname == 'CVL_KRA_Users' && (profile.userProfile.role.roleName == 'advisor' || profile.userProfile.role.roleName == 'Admin')) || 
    //                    (val.sidebarname == 'all_transaction_history' && (profile.userProfile.role.roleName == 'advisor' || profile.userProfile.role.roleName == 'Admin')) || 
    //                    (val.sidebarname == 'ecard_purcahse_history' && (profile.userProfile.role.roleName == 'advisor' || profile.userProfile.role.roleName == 'Admin')) || 
    //                    (val.sidebarname == 'MF_Transactions' && (profile.userProfile.role.roleName == 'advisor' || profile.userProfile.role.roleName == 'Admin')))){
    //                     val.status = true;
    //                 }
    //                 data.forEach(function (v, k) {
    //                     // console.log('forrrrrrrrr permiss 3', v , k);
    //                     if(v.permission){
    //                         v.permission.forEach(function(m, n){
    //                             // console.log('forrrrrrrrr permiss 4', m , n);
    //                             if(m.permissionName == val.sidebarname){
    //                                 val.status = m.status;
    //                             }
    //                         })
    //                     }
    //                 })
    //             })
    //         }
    //     })

    //     this.userPermissions = data;
    //     this.menus = menus;
    //     console.log(this.userPermissions, "this.userPermissions")
    //     console.log(this.menus, "this.this.menus")
    // }

    clicked(){
        this.permissionForMenus(this.userPermissions, this.permissionObj.userProfile.role);
    }

    permissionForMenus(userPermission,role){
        //for now to hardcode permission
        let isRoleOk = false;
        if(role.roleName === "Admin" || role.roleName === "Accounts" || role.roleName === "advisor"){
            isRoleOk = true;
        }else{
            isRoleOk = false;
        }
        //end for now to hardcode permission
        console.log('before menu', this.menus);

        this.userPermissions.forEach(moduleMenu => {

            this.menus.forEach(mainMenu => {
                if(moduleMenu.moduleName === mainMenu.sidebarname){
                    mainMenu.status = moduleMenu.status;
                }

                if(moduleMenu.permission){
                    moduleMenu.permission.forEach(permissionMenu => {
                        if(permissionMenu.permissionName === mainMenu.sidebarname){
                            mainMenu.status = permissionMenu.status;
                        }
                        if(mainMenu.sub){
                            mainMenu.sub.forEach(subMenu => {
                                if(permissionMenu.permissionName === subMenu.sidebarname){
                                    subMenu.status = permissionMenu.status;
                                }
                                //for now to hardcode permission
                                if((subMenu.sidebarname === "Tickets" || subMenu.sidebarname === "Payments_History") && isRoleOk === true){
                                    subMenu.status = true;
                                }
                                //end for now to hardcode permission
                            })
                        }
                    });
                }

            });

        });
  
        console.log('after menu', this.menus);
    }
}
