import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
} from '@angular/animations'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  

})
export class AppComponent {
  title = 'app';
  constructor(private _spinner: NgxSpinnerService) { 
     }
  getRouteAnimation(outlet) {
       this._spinner.show();
       setTimeout(() => {
        /** spinner ends after 5 seconds */
        this._spinner.hide();
    }, 5000);

      return outlet.activatedRouteData.animation
  }
}
