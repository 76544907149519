import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';
import { Router,RouterModule } from '@angular/router';
import { ApiService } from '../../app.service';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { UplaodXsipOrderComponent } from '../uplaod-xsip-order/uplaod-xsip-order.component';

@Component({
  selector: 'cdk-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
	isOpen: boolean = false;

  	//currentUser = null;
  	obj: any;
    name: 'Admin';
    roleName: any;
    // @Input() currentUse
    r = null;
  	@HostListener('document:click', ['$event', '$event.target'])
  	onClick(event: MouseEvent, targetElement: HTMLElement) {
    	if (!targetElement) {
     		return;
    	}

    	const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    	if (!clickedInside) {
      		this.isOpen = false;
    	}
  	}

  	constructor(private elementRef: ElementRef, private _router: Router, private _apiService: ApiService, private _snackbar: MatSnackBar, private _dialog: MatDialog) { }

  	ngOnInit() {
      this.obj = JSON.parse(sessionStorage.getItem('userDetailsObj'));
      this.name = this.obj.userProfile.name;
      this.roleName = this.obj.userProfile.role.roleName;
  	}

  logout(){
    let userId = this.obj.userProfile.id;
      this._dialog.closeAll();
      this._apiService.deleteToken(userId).subscribe(res =>{
        if(res.status == '200'){
          this._snackbar.open('Logged Out Successfully !', 'X', { duration:5000 });
          // location.reload();
          this._router.navigate(['/login']);
        }
      }, err =>{

      })
    };



    openXsipUpload(){
       const dialogRef = this._dialog.open(UplaodXsipOrderComponent, {
              width: '600px',
              disableClose: true,
            });
        dialogRef.afterClosed().subscribe(result => {
          // this.getAllUserForpartner(this.year);
        });
    }





}
