import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-exotel-dialog',
  templateUrl: './exotel-dialog.component.html',
  styleUrls: ['./exotel-dialog.component.scss']
})
export class ExotelDialogComponent implements OnInit {
  calledFromRegisteredName: boolean;
  exotelData: any;
  exotelDataForNonRegisteredUser: any;

  constructor(public dialogRef: MatDialogRef<ExotelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any) { }

  ngOnInit() {
    this.exotelData = this._data;
    if (!this.exotelData) {
      this.exotelDataForNonRegisteredUser = this._data;
      this.calledFromRegisteredName = false;
    } else {
      this.calledFromRegisteredName = true;
    }
    setTimeout(() => {
      this.closeDialogAfter30Seconds()
    }, 30000);
  }

  closeDialogAfter30Seconds() {
    this.exotelData['label'] = 'automaticallyClosed';
    this.dialogRef.close(this.exotelData);
  }


  saveDataAndCloseDialog(label) {
    if (label === 'Registered') {
      this.exotelData.label = 'Registered';
    }
    this.dialogRef.close(this.exotelData);
  }
}
